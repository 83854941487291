<template>
  <div class="logout">
    <div class="empty" @click="onCloseCsWidget"></div>
    <div class="widget">
      <div class="title">
        <span class="message">
          {{ this.title ? this.title : "确定退出吗？" }}
        </span>
      </div>
      <div class="row">
        <button class="confirm" @click="onLogout">确 认</button>
      </div>
      <div class="row">
        <button class="cancel" @click="onCloseCsWidget">取 消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { userCenterApi } from "@/api/userCenterApi.js";
export default {
  props: {
    title: String,
  },
  data() {
    return {};
  },
  methods: {
    onCloseCsWidget() {
      this.$EventBus.$emit("onCloseLogoutWidget", true);
    },
    onLogout() {
      userCenterApi.logout();
    },
  },
};
</script>

<style lang="less" scoped>
.logout {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  justify-content: space-between;
  .empty {
    flex: 1;
  }
  .widget {
    border-radius: 24px 24px 0px 0px;
    box-shadow: 4px 2px 10px 4px rgba(0, 0, 0, 0.5);
    clip-path: inset(-24px 0px 0px 0px);
    background: #ffffff;
    height: 480px;
    .title {
      font-weight: 500;
      font-size: 20px;
      color: #363637;
      letter-spacing: 3.92px;
      text-align: center;
      margin: 40px 0 60px 0;
    }
    .row {
      width: 100%;
      display: flex;
      justify-content: center;

      font-weight: 500;
      margin: 0 0 45px 0;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      line-height: 30px;
      .confirm {
        height: 46px;
        width: 220px;
        background: #acb9ff;
        border-radius: 14px;
        border: none;
      }
      .cancel {
        height: 46px;
        width: 220px;
        background: #4d52d1;
        border-radius: 14px;
        border: none;
      }
    }
  }
}
</style>
