<template>
  <div class="profile">
    <!-- <div class="header">
            <div class="empty"></div>
            <img :src="headerImg" />
        </div> -->
    <div class="main">
      <!-- <div class="phone">
                {{ phone }}
            </div> -->
      <div class="menu first">
        <div class="item">
          <div class="text">
            {{ phone }}
          </div>
        </div>
      </div>
      <div class="menu" @click="goToUserLearnPlan()">
        <div class="item">
          <div class="text">
            学习规划
          </div>
          <div class="btn">
            <img :src="btnImg" />
          </div>
        </div>
      </div>
      <div class="menu" @click="goToUserHomeWork()">
        <div class="item">
          <div class="text">
            作业系统
          </div>
          <div class="btn">
            <img :src="btnImg" />
          </div>
        </div>
      </div>
      <div class="menu" @click="onClickShowActiveCourseWidget()">
        <div class="item">
          <div class="text">
            激活课程
          </div>
          <div class="btn">
            <img :src="btnImg" />
          </div>
        </div>
      </div>
      <div class="menu" @click="onGotoAppDownload()">
        <div class="item">
          <div class="text">
            App下载
          </div>
          <div class="btn">
            <img :src="btnImg" />
          </div>
        </div>
      </div>
      <div class="menu" @click="onGotoUserRemark()">
        <div class="item">
          <div class="text">
            用户星球
          </div>
          <div class="btn">
            <img :src="btnImg" />
          </div>
        </div>
      </div>
      <div class="menu" @click="goToUserCourseExtendReview()">
        <div class="item">
          <div class="text">
            续课申请
          </div>
          <div class="btn">
            <img :src="btnImg" />
          </div>
        </div>
      </div>
      <div class="menu" @click="onClickShowCsWidget()">
        <div class="item">
          <div class="text">
            联系客服
          </div>
          <div class="btn">
            <img :src="btnImg" />
          </div>
        </div>
      </div>
      <div class="menu last" @click="onClickShowLogoutWidget()">
        <div class="item item-last">
          <div class="text">
            退出登录
          </div>
          <div class="btn">
            <img :src="btnImg" />
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <footerMenu></footerMenu>
    </div>

    <csWidget v-if="showCsWidget" :csId="'1'"> </csWidget>
    <activeCourseWidget v-if="showActiveCourse"> </activeCourseWidget>
    <logoutWidget v-if="showLogoutWidget"></logoutWidget>
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
import footerMenu from "@/components/footerMenu.vue";
import csWidget from "@/components/csWidget.vue";
import activeCourseWidget from "@/components/activeCourseWidget.vue";
import logoutWidget from "@/components/logoutWidget.vue";
import { Notify } from "vant";
import { userCenterApi } from "@/api/userCenterApi.js";

export default {
  components: {
    footerMenu,
    csWidget,
    activeCourseWidget,
    logoutWidget,
  },
  data() {
    return {
      showCsWidget: false,
      showActiveCourse: false,
      showLogoutWidget: false,
      headerImg: require("@/assets/images/profile-header.png"),
      btnImg: require("@/assets/images/profile-btn.png"),
      phone: "",
    };
  },
  methods: {
    onClickShowCsWidget() {
      this.showCsWidget = true;
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
    goToUserLearnPlan() {
      this.$router.push({
        path: "/userLearnPlan",
      });
    },
    goToUserHomeWork() {
      this.$router.push({
        path: "/userHomeWork",
      });
    },
    goToUserCourseExtendReview() {
      this.$router.push({
        path: "/courseExtend",
      });
    },
    onClickShowActiveCourseWidget() {
      this.showActiveCourse = true;
    },
    listenOnCloseActiveCourseWidget() {
      this.$EventBus.$on("onCloseActiveCourseWidget", (val) => {
        this.showActiveCourse = false;
      });
    },
    onClickShowLogoutWidget() {
      this.showLogoutWidget = true;
    },
    listenOnCloseLogoutWidget() {
      this.$EventBus.$on("onCloseLogoutWidget", (val) => {
        this.showLogoutWidget = false;
      });
    },
    onUid() {
      userCenterApi.uid({}).then((uid_ret) => {
        if (uid_ret.code == 0 && uid_ret.data) {
          this.phone = uid_ret.data.phone;
        } else {
          Notify({
            type: "danger",
            message: "获取用户信息失败",
          });
        }
      });
    },
    onGotoAppDownload() {
      this.$router.push({
        path: "/profile/appDownload",
      });
    },
    onGotoUserRemark() {
      this.$router.push({
        path: "/profile/userRemark",
        query: {
          taskId: 1,
        },
      });
    },
  },
  mounted() {
    this.listenOnCloseCsWidget();
    this.listenOnCloseActiveCourseWidget();
    this.listenOnCloseLogoutWidget();
    this.onUid();
  },
};
</script>

<style lang="less" scoped>
.profile {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    display: flex;
    flex-direction: column;
    height: 238px;
    background: linear-gradient(180deg, rgb(143, 112, 255) 0%, rgb(62, 67, 202) 100%);
    flex: 0 0 auto;
    .empty {
      flex: 1;
    }
    img {
      display: flex;
      width: 100%;
      height: auto;
    }
  }
  .main {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    align-items: center;
    .phone {
      font-size: 20px;
      color: #4e53d2;
      letter-spacing: 0.44px;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 0 48px 0;
    }
    .menu {
      height: 70px;
      width: 380px;
      background: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .item {
        height: 68px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(240, 240, 240);
        width: 360px;
        .text {
          font-size: 16px;
          color: #323233;
          letter-spacing: 0.44px;
          font-weight: 600;
          margin: 0 0 0 21px;
        }
        .btn {
          img {
            height: 21px;
            width: auto;
          }
          margin: 0 23px 0 0;
        }
      }
      .item-last {
        border-bottom: none;
      }
    }
    .first {
      border-radius: 16px 16px 0 0;
    }
    .last {
      border-radius: 0 0 16px 16px;
    }
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
