import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const activate = (ticket) => {
  return ajax.post(`${base}/api/itedu/v1/ticket/activate?ticket=${ticket}`);
};

export const ticketApi = {
  // import时得花括号明确
  activate: activate,
};
