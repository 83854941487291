<template>
  <div class="active">
    <div class="empty" @click="onCloseWidget"></div>
    <div class="widget">
      <div class="title">
        激活课程后到我的课程查看 ～
      </div>
      <div class="row">
        <input v-model="code" class="input-text" type="text" />
      </div>
      <div class="row">
        <button class="btn" @click="onActivate">激活</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
import { ticketApi } from "@/api/ticketApi.js";
export default {
  data() {
    return {
      code: "",
    };
  },
  methods: {
    onActivate() {
      ticketApi.activate(this.code).then((ret) => {
        if (ret.code == 0 && ret.data) {
          console.log(ret);
          Notify({
            type: "success",
            message: "激活课程成功，到我的课程查看",
          });
          this.onCloseWidget();
        }
      });
    },
    onCloseWidget() {
      this.$EventBus.$emit("onCloseActiveCourseWidget", true);
    },
  },
};
</script>

<style lang="less" scoped>
.active {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  justify-content: space-between;
  .empty {
    flex: 1;
  }
  .widget {
    border-radius: 24px 24px 0px 0px;
    box-shadow: 4px 2px 10px 4px rgba(0, 0, 0, 0.5);
    clip-path: inset(-24px 0px 0px 0px);
    background: #ffffff;
    height: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin: 36px 0 24px 0;
      font-size: 20px;
      color: #363637;
      letter-spacing: 3.92px;
      text-align: center;
      font-weight: 500;
    }
    .input-text {
      width: 290px;
      height: 46px;
      background: #f1f1f1;
      border-radius: 14px;
      border: none;
      font-size: 18px;
      color: #919191;
      padding: 0 10px;
      margin: 0 0 68px 0;
    }
    .btn {
      width: 310px;
      height: 46px;
      background: #4d52d1;
      border-radius: 14px;
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      line-height: 30px;
      font-weight: 500;
      border: none;
    }
  }
}
</style>
